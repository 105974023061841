import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from '../utils/Global';
import Navbar from './globals/navbar';
import Footer from './globals/Footer';

// import "./layout.css"

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Navbar />
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
