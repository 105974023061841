import { createGlobalStyle } from "styled-components"
// import { normalize } from "polished"
// ${normalize()};

const GlobalStyle = createGlobalStyle`


html {
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #262626;
  background: #fff;

}

a {
  text-decoration: none;
}

 li {
    list-style-type: none;
  }
`
export default GlobalStyle
